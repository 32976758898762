import React, { useRef, useContext } from 'react';
import { useDeepCompareEffect, useIntersection } from 'react-use';
import { VisibleSectionsContext } from '../providers/VisibleSectionsProvider';

const IntersectionSensor = ({ section, children }) => {
  const { visibleSections, updateVisibleSection } = useContext(
    VisibleSectionsContext,
  );
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });
  const isIntersecting = intersection?.isIntersecting;

  useDeepCompareEffect(() => {
    if (typeof isIntersecting === 'boolean') {
      const concernSection = visibleSections.find(
        visibleSection => visibleSection.section === section,
      );

      if (concernSection.isIntersecting !== isIntersecting) {
        updateVisibleSection({ section, isIntersecting });
      }
    }
  }, [{ isIntersecting }]);

  return <div ref={intersectionRef}>{children}</div>;
};

export default IntersectionSensor;
