import { createContext, useState } from 'react';
import React from 'react';

export const VisibleSectionsContext = createContext();

const initialState = [
  { section: '#presentation', isIntersecting: false },
  { section: '#objectifs', isIntersecting: false },
  { section: '#campagne', isIntersecting: false },
  { section: '#comment-ca-marche', isIntersecting: false },
  { section: '#actions', isIntersecting: false },
  { section: '#histoire', isIntersecting: false },
  { section: '#membres', isIntersecting: false },
  { section: '#actualites', isIntersecting: false },
  { section: '#photos', isIntersecting: false },
];

const VisibleSectionsProvider = ({ children }) => {
  const [visibleSections, setVisibleSections] = useState(initialState);

  const updateVisibleSection = newVisibleSection => {
    const index = visibleSections.findIndex(
      ({ section }) => section === newVisibleSection.section,
    );
    const draft = [...visibleSections];

    draft[index].isIntersecting = newVisibleSection.isIntersecting;
    setVisibleSections(draft);
  };

  return (
    <VisibleSectionsContext.Provider
      value={{
        visibleSections,
        updateVisibleSection,
      }}
    >
      {children}
    </VisibleSectionsContext.Provider>
  );
};

export default VisibleSectionsProvider;
