import React from 'react';
import { graphql } from 'gatsby';
import PartnersSection from 'components/PartnersSection';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import GettingInvolvedSection from '../components/GettingInvolvedSection';

const Involvment = ({ data }) => {
  const {
    datoCmsHomepage,
    allDatoCmsPartner,
    datoCmsGlobal,
    datoCmsGettingInvolvedPage,
    datoCmsMainMenu,
  } = data;

  return (
    <Layout
      globalData={datoCmsGlobal}
      mainMenuData={datoCmsMainMenu}
      engagementKinds={datoCmsGettingInvolvedPage?.involvementKind}
    >
      <Seo
        title={datoCmsGettingInvolvedPage?.metaTags?.title}
        description={datoCmsGettingInvolvedPage?.metaTags?.description}
      />
      <GettingInvolvedSection
        data={datoCmsGettingInvolvedPage}
        variant="topSection"
      />
      <PartnersSection
        data={datoCmsHomepage}
        partners={allDatoCmsPartner?.edges ?? []}
      />
    </Layout>
  );
};

export const query = graphql`
  query {
    datoCmsHomepage {
      partnersOvertitle
      partnersTitle
    }

    datoCmsGettingInvolvedPage {
      metaTags {
        description
        title
      }
      gettingInvolvedOvertitle
      gettingInvolvedTitle
      gettingInvolvedContent
      involvementKind {
        title
        description
        information
        cta
        ctaLink
        icon {
          url
        }
      }
    }

    datoCmsGlobal {
      facebookLink
      instagramLink
      linkedinLink
      mailContact
      twitterLink
      footerText
      terms
    }

    allDatoCmsPartner {
      edges {
        node {
          id
          name
          logo {
            url
            alt
            ... on DatoCmsFileField {
              gatsbyImageData
            }
          }
        }
      }
    }
    datoCmsMainMenu {
      blogLink
      galleryLink
      homepageLink
      involvmentLink
    }
  }
`;

export default Involvment;
